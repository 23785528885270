import React from "react";
import "./App.css";

function App() {
    return (
        <div className="container">
            <div className="content">
                <h1>Klaire</h1>
                <p className="tagline">L'intelligence artificielle pour le notariat</p>
                <a 
                    href="mailto:contact@klaire.cc" 
                    className="contact-button"
                >
                    Contactez-nous
                </a>
            </div>
        </div>
    );
}

export default App;
